import React from 'react';

import logo from "../../../src/images/entrypage/logo-main.svg";
import SvgIcon from "../Icons";

import './index.sass';


const Footer = ({ addressLine,
                  cvr,
                  email,
                  facebookUrl,
                  hideContacts,
                  instagramUrl,
                  phone,
                  postalCode,
                  postalName }) => {


  return (
    <div className="footer">
      <div className="container">
        <img alt="logo"
             className="footer__logo lazyload"
             data-expand="100"
             data-sizes="auto"
             data-src={logo}
        />

        <div className="footer__info">
          <a className="footer__cookie-link" href="/privatlivspolitik" target="_blank">
            Cookie- og privatlivspolitik
          </a>
        </div>

        <div className="footer__info">
          { addressLine } · { postalCode } { postalName }
        </div>

        { !hideContacts &&
          <div className="footer__info">
            Tlf.&nbsp;
            <a className="footer__phone-link" href={`tel://${phone}`}>{ phone }</a>
            &nbsp;·&nbsp;
            <a className="footer__email-link" href={`mailto: ${ email }`}>{ email }</a>
          </div>
        }

        { cvr &&
          <div className="footer__info">
            CVR nr. { cvr }
          </div>
        }
      </div>

      <div className="footer__social-icons">
        <a href={instagramUrl} target="_blank">
          <SvgIcon name="instagram" size={24} />
        </a>
        <a href={facebookUrl} target="_blank">
          <SvgIcon name="facebook" size={24} />
        </a>
      </div>
    </div>
  )
}

export default Footer;
