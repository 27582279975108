import React, { useEffect, useRef, useState } from 'react';

import './index.sass';


const DropdownWithSearch = ({ bodyTitle,
                              handleSearch,
                              handleSelect,
                              inputValue,
                              options,
                              placeholder,
                              searchIcon,
                              searchResultsComponent,
                              showDefaultOptions,
                              showSearchResults,
                              wrapperClass }) => {

  const triggerWrapper = useRef();
  const searchInput = useRef();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
  }, []);


  const handleClickOutside = (e) => {
    const target = e.target;
    const parent = target.parentNode;
    const isClicalble = target.getAttribute('role') === 'clickable' || parent.getAttribute('role') === 'clickable';

    !isClicalble && target !== triggerWrapper.current && !triggerWrapper.current.contains(target) && setIsOpen(false);
  };

  const setQuery = (e) => {
    !isOpen && setIsOpen(true);
    handleSearch(e.target.value);
  };


  return (
    <div className={`dropdown ${isOpen ? 'open' : ''} ${wrapperClass || ''}`}>
      <div className="dropdown__trigger" onClick={() => setIsOpen(!isOpen)} ref={triggerWrapper}>
        { searchIcon }
        <input
          className="dropdown__search-input"
          onChange={setQuery}
          placeholder={placeholder}
          ref={searchInput}
          type="text"
          value={inputValue}
        />
      </div>

      { isOpen && options && options.length &&
        <div className="dropdown__body">

          { showSearchResults && searchResultsComponent && searchResultsComponent }

          { (showDefaultOptions || !(showSearchResults && searchResultsComponent)) && bodyTitle && <div className="dropdown__body-title">{ bodyTitle }</div> }

          { (showDefaultOptions || !(showSearchResults && searchResultsComponent)) &&
            <div className="dropdown__options-wrapper">
              { options.map(option =>
                <div className="dropdown__option" key={`dropdown-option-${option.id}`} onClick={() => handleSelect(option)}>
                  { option.name }
                </div>
              )}
            </div>
          }
        </div>
      }
    </div>
  )
};

export default DropdownWithSearch;
