import React from 'react';
import PropTypes from 'prop-types';

const SvgIcon = ({size, height, width, name, onClick, className, fill}) =>  {

    // Use id's from assets/images/common-icons.svg
  return (
    <svg
      fill={fill || ''}
      className={className || ''}
      onClick={onClick || null}
      width={width ? width : size}
      height={height ? height : size}
      id={`svg-${name}`}
      style={{ minWidth: width ? width : size }}
    >
      <use href={`#common-icons-${name}`} />
    </svg>
  );
}


SvgIcon.defaultProps = {
  size: 18,
}

SvgIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
};


export default SvgIcon;
