import React, { useEffect, useRef, useState } from 'react';

import logo from '../../../src/images/entrypage/logo-main.svg';
import SvgIcon from "../Icons";
import PlacesSearch from "../PlacesSearch";

import './index.sass';


const TopMenu = ({ becomeAdvertiserPath,
                   categories,
                   helpPath,
                   guidesPath,
                   loginPath,
                   regionsOptions,
                   signedIn,
                   withSearch }) => {

  const searchBodyWrapper = useRef();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchIsOpen, setSearchIsOpen] = useState(false);


  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);


  const handleClickOutside = (e) => {
    const searchWrapper = searchBodyWrapper.current;
    const target = e.target;
    const isSearchWrapper = searchWrapper && (target === searchWrapper || searchWrapper.contains(target));
    const isDropdownOption = target.classList.contains('dropdown__option') || target.classList.contains('basic-dropdown__option');

    searchWrapper && !isSearchWrapper && !isDropdownOption && target.id !== 'search-toggle' && setSearchIsOpen(false);
  };


  const handleOpenMenu = () => {
    setMenuIsOpen(!menuIsOpen);
    searchIsOpen ? setSearchIsOpen(false) : document.body.classList.toggle('mobile-filters-open');
  };


  const handleOpenSearch = () => {
    setSearchIsOpen(!searchIsOpen);
    menuIsOpen ? setMenuIsOpen(false) : document.body.classList.toggle('mobile-filters-open');
  };


  return (
    <header className="topbar">
      <div className='topbar__container container'>

        <a href='/'>
          <img alt="logo"
               className="topbar__logo lazyload"
               data-expand="100"
               data-sizes="auto"
               data-src={logo}
          />
        </a>

        <div className="topbar__menu-left">

          <div className="topbar__mobile-menu-toggle-wrapper">
            { withSearch &&
              <div className={`topbar__search-toggle ${searchIsOpen ? 'open' : ''}`} onClick={handleOpenSearch} id="search-toggle">
                <SvgIcon name="search" size={16} />
              </div>
            }

            <div className={`topbar__menu-toggle ${menuIsOpen ? 'cross' : ''}`} onClick={handleOpenMenu}>
              <div className="topbar__burger-wrapper">
                <span/>
                <span/>
                <span/>
                <span/>
              </div>
            </div>
          </div>

          <div className={`topbar__menu-wrapper ${menuIsOpen ? 'shown' : ''}`}>
            <ul className="topbar__menu-left-list">
              <li><a href="/">Forside</a></li>
              <li><a href={guidesPath}>Guides til din fest</a></li>
              { !signedIn && <li><a href={becomeAdvertiserPath}>Opret annonce</a></li> }
              <li><a href={helpPath}>Kontakt</a></li>
              <li>
                <a href={loginPath[1]}>
                  { loginPath[0] }
                </a>
              </li>
            </ul>
          </div>

          { withSearch &&
            <div className={`topbar__menu-wrapper search ${searchIsOpen ? 'shown' : ''}`}>
              <PlacesSearch
                categories={categories}
                regionsOptions={regionsOptions}
                wrapperRef={searchBodyWrapper}
              />
            </div>
          }
        </div>
      </div>
    </header>
  );
}

export default TopMenu;
